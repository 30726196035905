import request from '@/plugin/axios';
import setting from '@/setting';
var hostPath = setting.appApi;

var pos = function pos(host, action, data) {
  if (!data) {
    data = {};
  }

  return request({
    url: host + action,
    method: 'post',
    data: data
  });
};

export function accountCodeBind(data) {
  return pos(hostPath.base, 'tw/bind', data);
}
export function accountLogin(data) {
  return pos(hostPath.base, 'account/login', data);
}
export function accountList(data) {
  return pos(hostPath.base, 'account', data);
}
export function accountAdd(data) {
  return pos(hostPath.base, 'account/handle', data);
}
export function accountDel(data) {
  return pos(hostPath.base, 'account/delete', data);
}
export function menuList(data) {
  return pos(hostPath.base, 'system/menu', data);
}
export function menuBatch(data) {
  return pos(hostPath.base, 'system/menu/batch/handle', data);
}
export function menuAdd(data) {
  return pos(hostPath.base, 'system/menu/handle', data);
}
export function menuDel(data) {
  return pos(hostPath.base, 'system/menu/delete', data);
}
export function roleList(data) {
  return pos(hostPath.base, 'account/roles', data);
}
export function roleAdd(data) {
  return pos(hostPath.base, 'account/roles/handle', data);
}
export function roleDel(data) {
  return pos(hostPath.base, 'account/roles/delete', data);
}
export function areaList(data) {
  return pos(hostPath.base, 'area', data);
}
export function areaListByPid(data) {
  return pos(hostPath.base, 'area/index2', data);
}
export function areaAdd(data) {
  return pos(hostPath.base, 'area/handle', data);
}
export function areaDel(data) {
  return pos(hostPath.base, 'area/delete', data);
}
export function userAreaDel(data) {
  return pos(hostPath.base, 'user/area/delete', data);
}
export function systemTempList(data) {
  return pos(hostPath.base, 'tools/generator', data);
}
export function systemTempAdd(data) {
  return pos(hostPath.base, 'tools/generator/handle', data);
}
export function systemTempDel(data) {
  return pos(hostPath.base, 'tools/generator/delete', data);
}
export function systemTempDetail(data) {
  return pos(hostPath.base, 'tools/generator/detail', data);
}
export function systemTempDetailAdd(data) {
  return pos(hostPath.base, 'tools/generator/detail/handle', data);
}
export function systemInfo(data) {
  return pos(hostPath.base, 'common/system/info', data);
}
export function systemWebConfig(data) {
  return pos(hostPath.base, 'switch', data);
}
export function systemWebConfigAdd(data) {
  return pos(hostPath.base, 'switch/handle', data);
}
export function systemWebConfigDel(data) {
  return pos(hostPath.base, 'switch/delete', data);
} //用户列表

export function userList(data) {
  return pos(hostPath.base, 'user', data);
}
export function userDel(data) {
  return pos(hostPath.base, 'user/delete', data);
}
export function userAdd(data) {
  return pos(hostPath.base, 'user/handle', data);
}
export function productCategory(data) {
  //产品分类
  return pos(hostPath.base, 'product/category', data);
}
export function productCategoryAdd(data) {
  //产品分类
  return pos(hostPath.base, 'product/category/handle', data);
}
export function productCategoryDel(data) {
  //产品分类
  return pos(hostPath.base, 'product/category/delete', data);
}
export function productAdd(data) {
  //产品添加
  return pos(hostPath.base, 'product/handle', data);
}
export function productDel(data) {
  //产品删除
  return pos(hostPath.base, 'product/delete', data);
}
export function productList(data) {
  //产品列表
  if (data.where.is_main === 0) {
    data.allSys = 1;
  }

  return pos(hostPath.base, 'product', data);
}
export function productPackage(data) {
  //产品套餐编辑
  return pos(hostPath.base, 'product/package/handle', data);
}
export function productBatchShow(data) {
  //产品批量上下架
  return pos(hostPath.base, 'product/online/batch', data);
}
export function productPriceAdd(data) {
  //产品等级价格
  return pos(hostPath.base, 'product/price/handle', data);
}
export function orderList(data) {
  //订单列表
  return pos(hostPath.base, 'order', data);
}
export function orderProduct(data) {
  //订单编辑
  return pos(hostPath.base, 'order/product', data);
}
export function orderAdd(data) {
  return pos(hostPath.base, 'order/handle', data);
}
export function orderCancel(data) {
  //订单撤单
  return pos(hostPath.base, 'order/cancel', data);
}
export function adList(data) {
  //广告
  return pos(hostPath.base, 'slide', data);
}
export function adAdd(data) {
  //广告
  return pos(hostPath.base, 'slide/handle', data);
}
export function adDel(data) {
  //广告
  return pos(hostPath.base, 'slide/delete', data);
}
export function opLogs(data) {
  //系统操作日志
  return pos(hostPath.base, 'logs', data);
}
export function tradeFlow(data) {
  //交易流水
  data.allSys = 1;
  return pos(hostPath.base, 'flowing', data);
}
export function tradeFlowDeduction(data) {
  //不扣款
  return pos(hostPath.base, 'flowing/transaction', data);
}
export function configBalanceList(data) {
  //结算配置
  return pos(hostPath.base, 'balance/config', data);
}
export function configBalanceAdd(data) {
  return pos(hostPath.base, 'balance/config/handle', data);
}
export function configBalanceDel(data) {
  return pos(hostPath.base, 'balance/config/delete', data);
}
export function configGradeList(data) {
  //定级配置
  console.log('aaaaaa', data);
  return pos(hostPath.base, 'grade/config', data);
}
export function configBonusList(data) {
  //奖金配置
  return pos(hostPath.base, 'bonus/config', data);
}
export function configStoreList(data) {
  //定级配置
  return pos(hostPath.base, 'store/config', data);
}
export function configLevelList(data) {
  //定级配置
  return pos(hostPath.base, 'level/config', data);
}
export function recommendList(data) {
  //推荐网
  return pos(hostPath.base, 'user/relationship', data);
}
export function recommendHistoryList(data) {
  //推荐网
  return pos(hostPath.base, 'user/relationship/history', data);
}
export function placeList(data) {
  //链路图
  return pos(hostPath.base, 'user/place', data);
}
export function expectNumList(data) {
  //链路图
  return pos(hostPath.base, 'balance/total', data);
}
export function memberMulUpdateState(data) {
  //批量更新用户状态
  return pos(hostPath.base, 'user/status/batch', data);
}
export function gradeMulUpdate(data) {
  //批量更新等级
  return pos(hostPath.base, 'grade/update', data);
}
export function levelMulUpdate(data) {
  //批量更新等级
  return pos(hostPath.base, 'user/level/update', data);
}
export function activeMulUpdate(data) {
  //批量更新等级
  return pos(hostPath.base, 'user/active', data);
}
export function gradeLog(data) {
  //定级日志
  return pos(hostPath.base, 'grade/logs', data);
}
export function levelLog(data) {
  //资格定级日志
  return pos(hostPath.base, 'level/logs', data);
}
export function staticTotal(data) {
  //统计
  return pos(hostPath.base, 'statistics/overview', data);
}
export function staticAllTotal(data) {
  //统计
  return pos(hostPath.base, 'statistics/total', data);
}
export function staticMemberAdd(data) {
  //统计
  return pos(hostPath.base, 'statistics/member', data);
}
export function staticIncome(data) {
  //统计
  return pos(hostPath.base, 'statistics/income', data);
}
export function staticGrade(data) {
  //统计
  return pos(hostPath.base, 'statistics/group', data);
}
export function staticProduct(data) {
  //统计
  return pos(hostPath.base, 'statistics/product', data);
}
export function staticPgv(data) {
  //统计
  return pos(hostPath.base, 'statistics/pgv', data);
}
export function sendBonusLog(data) {
  //奖金发放日志
  return pos(hostPath.base, 'bonus', data);
}
export function memberMoveAdd(data) {
  //移动点位
  return pos(hostPath.base, 'link/move', data);
}
export function memberMoveDel(data) {
  //移动点位
  return pos(hostPath.base, 'move/delete', data);
}
export function memberMoveList(data) {
  //移动点位
  return pos(hostPath.base, 'move', data);
}
export function memberRepairMoney(data) {
  //补扣款
  return pos(hostPath.base, 'finance/handle', data);
}
export function memberTakeList(data) {
  //提现申请
  return pos(hostPath.base, 'withdrawal', data);
}
export function memberTakeAdd(data) {
  //提现申请
  return pos(hostPath.base, 'withdrawal/handle', data);
}
export function memberInfoApplyList(data) {
  //信息修改申请
  return pos(hostPath.base, 'user/profile', data);
}
export function memberInfoApplyAdd(data) {
  //信息修改申请
  return pos(hostPath.base, 'user/profile/handle', data);
}
export function memberBatchRecharge(data) {
  //批量设置充值中心
  return pos(hostPath.base, 'user/recharge/handle', data);
}
export function memberBatchStore(data) {
  //批量设置充值中心
  return pos(hostPath.base, 'user/store/handle', data);
}
export function memberBonusSource(data) {
  //会员奖金追溯
  return pos(hostPath.base, 'bonus/source', data);
}
export function memberPwdModify(data) {
  //密码修改
  return pos(hostPath.base, 'user/password/change', data);
}
export function balanceReset(data) {
  //充值结算状态
  return pos(hostPath.base, 'balance/reset', data);
}
export function balanceStatus(data) {
  //获取结算状态
  return pos(hostPath.base, 'balance/status', data);
}
export function expressCheckout(data) {
  //产品批量出库
  return pos(hostPath.base, 'express/checkout', data);
}
export function expressCheckoutAll(data) {
  //产品全部出库
  return pos(hostPath.base, 'express/checkout/all', data);
}
export function expressDeliver(data) {
  //产品批量发货
  return pos(hostPath.base, 'express/deliver', data);
}
export function expressDeliverAll(data) {
  //产品全部发货
  return pos(hostPath.base, 'express/deliver/all', data);
}
export function expressOrderLock(data) {
  //锁单
  return pos(hostPath.base, 'order/lock', data);
}
export function expressOrderLockAll(data) {
  //锁单
  return pos(hostPath.base, 'order/lock', data);
}
export function expressLock(data) {
  //锁单
  return pos(hostPath.base, 'express/lock', data);
}
export function expressLockAll(data) {
  //锁单
  return pos(hostPath.base, 'express/lock/all', data);
}
export function expressImport(data) {
  //快速导入
  return pos(hostPath.base, 'express/import', data);
}
export function expressReset(data) {
  //批量重置订单
  return pos(hostPath.base, 'express/reset', data);
}
export function pythonBalance(data) {
  //批量重置订单
  var args = ['rebalancesys', '-expectnum', data.expect_num, '-systemid', setting.systemId]; // let args = ['balance']

  return pos(hostPath.base, 'balance/exec', {
    args: args
  });
}
export function pythonNewBalance(data) {
  //批量重置订单
  var args = ['newbalance']; // let args = ['balance']

  return pos(hostPath.base, 'balance/exec', {
    args: args
  });
}
export function sendCmd(data) {
  //批量重置订单
  return pos(hostPath.base, 'balance/exec', {
    args: data
  });
}
export function fileList(data) {
  //批量重置订单
  return pos(hostPath.base, 'report', data);
}
export function fileDel(data) {
  //批量重置订单
  return pos(hostPath.base, 'report/delete', data);
}
export function newsList(data) {
  //新闻
  return pos(hostPath.base, 'news', data);
}
export function newsAdd(data) {
  //新闻
  return pos(hostPath.base, 'news/handle', data);
}
export function newsDel(data) {
  //新闻
  return pos(hostPath.base, 'news/delete', data);
}
export function bankList(data) {
  //公司银行账户
  return pos(hostPath.base, 'finance/bank', data);
}
export function bankAdd(data) {
  //公司银行账户
  return pos(hostPath.base, 'finance/bank/handle', data);
}
export function bankDel(data) {
  //公司银行账户
  return pos(hostPath.base, 'finance/bank/delete', data);
}
export function financeApply(data) {
  //汇款
  return pos(hostPath.base, 'finance/apply', data);
}
export function financeApplyAdd(data) {
  //汇款审核
  return pos(hostPath.base, 'finance/apply/handle', data);
}
export function clientLogin(data) {
  //汇款审核
  return pos(hostPath.base, 'user/login', data);
}
export function systemSwitchList(data) {
  //系统开关
  return pos(hostPath.base, 'switch', data);
}
export function systemSwitchAdd(data) {
  //系统开关
  return pos(hostPath.base, 'switch/handle', data);
}
export function manualSendBonus(data) {
  //手动发放奖金
  return pos(hostPath.base, 'balance/send/manual', data);
}
export function manualUnSendBonus(data) {
  //撤销发放
  return pos(hostPath.base, 'balance/rollback', data);
}
export function systemReportList(data) {
  //撤销发放
  return pos(hostPath.base, 'report/expect/total', data);
}
export function memberLoginLog(data) {
  //撤销发放
  return pos(hostPath.base, 'user/login/logs', data);
}
export function bonusSourceList(data) {
  //奖金追溯
  return pos(hostPath.base, 'bonus/source', data);
}
export function staticsOrderList(data) {
  //奖金追溯
  return pos(hostPath.base, 'statistics/order/count', data);
}
export function staticsGradeList(data) {
  //奖金追溯
  return pos(hostPath.base, 'statistics/batch/next/level', data);
}
export function staticsProductList(data) {
  //奖金追溯
  return pos(hostPath.base, 'order/excel', data);
}
export function questionnaireList(data) {
  //问卷
  return pos(hostPath.base, 'questionnaire', data);
}
export function questionnaireStatic(data) {
  //问卷统计
  return pos(hostPath.base, 'questionnaire/statistics', data);
}
export function memberArea(data) {
  return pos(hostPath.base, 'user/area', data);
}
export function auditPassArea(data) {
  return pos(hostPath.base, 'user/area/audit/pass', data);
}
export function auditRejectArea(data) {
  return pos(hostPath.base, 'user/area/audit/reject', data);
}
export function agentList(data) {
  //用户代理
  return pos(hostPath.base, 'agent', data);
}
export function agentHandle(data) {
  //用户代理
  return pos(hostPath.base, 'agent/handle', data);
}
export function agentLog(data) {
  //用户代理
  return pos(hostPath.base, 'agent/logs', data);
}
export function bonusSearch(data) {
  //用户代理
  return pos(hostPath.base, 'bonus/search', data);
}
export function articleList(data) {
  //用户代理
  return pos(hostPath.base, 'article', data);
}
export function articleAdd(data) {
  //用户代理
  return pos(hostPath.base, 'article/handle', data);
}
export function articleDel(data) {
  //用户代理
  return pos(hostPath.base, 'article/delete', data);
}
export function takeSign(data) {
  //提现签约
  return pos(hostPath.base, 'withdrawal/sign/handle', data);
}
export function createEmptyPoint(data) {
  //创建空点位
  return pos(hostPath.base, 'user/create/user', data);
}
export function makeUserNumber(data) {
  //创建空点位
  return pos(hostPath.base, 'user/make/number', data);
}
export function stockCheck(data) {
  //创建空点位
  return pos(hostPath.base, 'product/stock/check', data);
}
export function activeList51(data) {
  //创建空点位
  return pos(hostPath.base, 'activity/direct13', data);
}
export function activeTravelList(data) {
  //创建空点位
  return pos(hostPath.base, 'active/travel', data);
}
export function activeList11(data) {
  //创建空点位
  return pos(hostPath.base, 'active/mark', data);
} //区域审核

export function areaCheckList(data) {
  //创建空点位
  return pos(hostPath.base, 'month/fit', data);
}
export function areaCheckHandle(data) {
  //创建空点位
  return pos(hostPath.base, 'month/fit/handle', data);
}
export function areaCheckDel(data) {
  //创建空点位
  return pos(hostPath.base, 'month/fit/del', data);
} //区域申请

export function areaApplyList(data) {
  //创建空点位
  return pos(hostPath.base, 'user/area', data);
}
export function areaApplyHandle(data) {
  //创建空点位
  return pos(hostPath.base, 'user/area/handle', data);
}
export function areaApplyDel(data) {
  //创建空点位
  return pos(hostPath.base, 'user/area/del', data);
}
export function areaApplyExamine(data) {
  //创建空点位
  return pos(hostPath.base, 'user/area/examine', data);
}
export function areaApplyUpdate(data) {
  //创建空点位
  return pos(hostPath.base, 'user/area/update', data);
}
export function areaApplyAllList(data) {
  //创建空点位
  return pos(hostPath.base, 'user/area/list', data);
}
export function areaLockList(data) {
  //创建空点位
  return pos(hostPath.base, 'user/area/lock', data);
}
export function areaLockHandle(data) {
  //创建空点位
  return pos(hostPath.base, 'user/area/lock/create', data);
}
export function areaLockDel(data) {
  //创建空点位
  return pos(hostPath.base, 'user/area/lock/delete', data);
} //培训报名

export function trainConfig(data) {
  return pos(hostPath.base, 'activity', data);
}
export function trainConfigHandle(data) {
  return pos(hostPath.base, 'activity/handle', data);
}
export function trainConfigDel(data) {
  return pos(hostPath.base, 'activity/delete', data);
}
export function trainUsers(data) {
  return pos(hostPath.base, 'activity/users', data);
}
export function trainUsersHandle(data) {
  return pos(hostPath.base, 'activity/users/handle', data);
}
export function trainUsersDel(data) {
  return pos(hostPath.base, 'activity/users/delete', data);
}
export function trainPreset(data) {
  return pos(hostPath.base, 'activity/preset', data);
}
export function trainPresetHandle(data) {
  return pos(hostPath.base, 'activity/preset/handle', data);
}
export function trainPresetDel(data) {
  return pos(hostPath.base, 'activity/preset/delete', data);
}
export function trainPresetImport(data) {
  return pos(hostPath.base, 'activity/preset/import', data);
}
export function shopBonusConfig(data) {
  return pos(hostPath.base, 'shop/bonus/config', data);
}
export function shopBonusConfigHandle(data) {
  return pos(hostPath.base, 'shop/bonus/config/handle', data);
}
export function shopBonusConfigDel(data) {
  return pos(hostPath.base, 'shop/bonus/config/delete', data);
}
export function goodsList(data) {
  return pos(hostPath.base, 'goods', data);
}
export function goodsDel(data) {
  return pos(hostPath.base, 'goods/delete', data);
}
export function goodsHandle(data) {
  return pos(hostPath.base, 'goods/handle', data);
}
export function goodsAttrList(data) {
  return pos(hostPath.base, 'goods/key', data);
}
export function goodsAttrDel(data) {
  return pos(hostPath.base, 'goods/key/delete', data);
}
export function goodsAttrHandle(data) {
  return pos(hostPath.base, 'goods/key/handle', data);
}
export function goodsCatList(data) {
  return pos(hostPath.base, 'goods/category', data);
}
export function goodsCatDel(data) {
  return pos(hostPath.base, 'goods/category/delete', data);
}
export function goodsCatHandle(data) {
  return pos(hostPath.base, 'goods/category/handle', data);
}
export function goodsAttrValueList(data) {
  return pos(hostPath.base, 'goods/value', data);
}
export function goodsAttrValueDel(data) {
  return pos(hostPath.base, 'goods/value/delete', data);
}
export function goodsAttrValueHandle(data) {
  return pos(hostPath.base, 'goods/value/handle', data);
}
export function goodsSpecsList(data) {
  return pos(hostPath.base, 'goods/specs', data);
}
export function goodsSpecsHandle(data) {
  return pos(hostPath.base, 'goods/specs/batch', data);
}
export function goodsOrder(data) {
  return pos(hostPath.base, 'goods/order', data);
}
export function goodsOrderHandle(data) {
  return pos(hostPath.base, 'goods/order/handle', data);
}
export function refreshReport(data) {
  return pos(hostPath.base, 'script/report/remake', data);
}
export function goodsImport(data) {
  return pos(hostPath.base, 'goods/import', data);
}
export function memberShopFitList(data) {
  return pos(hostPath.base, 'user/shop/share', data);
}
export function memberShopFitDel(data) {
  return pos(hostPath.base, 'user/shop/share/delete', data);
}
export function memberShopFitHandle(data) {
  return pos(hostPath.base, 'user/shop/share/handle', data);
}
export function memberShopFitLog(data) {
  return pos(hostPath.base, 'user/shop/share/logs', data);
}
export function activeCar(data) {
  return pos(hostPath.base, 'activity/car', data);
}
export function activeTenCar(data) {
  return pos(hostPath.base, 'activity/car/ten', data);
}
export function activeSixTravel(data) {
  return pos(hostPath.base, 'api/activeSixTravel/index', data);
}
export function activeYinshuiji(data) {
  return pos(hostPath.base, 'api/ysjActivity/page', data);
}
export function shopStoreFlowing(data) {
  return pos(hostPath.base, 'diancang', data);
}
export function shopStoreStock(data) {
  return pos(hostPath.base, 'diancang/stock', data);
}
export function postFee(data) {
  return pos(hostPath.base, 'post/fee', data);
}
export function postFeeAdd(data) {
  return pos(hostPath.base, 'post/fee/handle', data);
}
export function orderGlassList(data) {
  return pos(hostPath.base, 'order/ex', data);
}
export function productEx(data) {
  return pos(hostPath.base, 'product/ex', data);
}
export function productCategoryLimit(data) {
  return pos(hostPath.base, 'api/categoryLimit/index', data);
}
export function productCategoryLimitAdd(data) {
  return pos(hostPath.base, 'api/categoryLimit/handle', data);
}
export function productCategoryLimitDel(data) {
  return pos(hostPath.base, 'api/categoryLimit/delete', data);
}
export function dsActiveLogList(data) {
  return pos(hostPath.base, 'api/activeDs/list', data);
}
export function areaApplyActiveDate(data) {
  return pos(hostPath.base, 'api/areaActiveDate/page', data);
}
export function areaApplyActiveDateHandle(data) {
  return pos(hostPath.base, 'api/areaActiveDate/handle', data);
}
export function areaApplyActiveDateDel(data) {
  return pos(hostPath.base, 'api/areaActiveDate/delete', data);
}