import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import { areaCheckList, areaCheckHandle, areaCheckDel } from '@/api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  name: 'memberAreaFit',
  data: function data() {
    var _this = this;

    return {
      searchModel_1000: {
        "del": false,
        "id": "id_1000",
        "isCollapse": false,
        "operateData": [{
          "icon": "el-icon-search",
          "tag": "search",
          "title": "搜索",
          "type": "primary"
        }, {
          "icon": "el-icon-position",
          "tag": "export",
          "title": "导出",
          "type": "primary"
        }],
        "searchData": [{
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "type": "button",
            "title": "乡镇",
            "total": 0,
            "field": [{
              "key": "area_type",
              "value": "0"
            }]
          }, {
            "field": [{
              "key": "area_type",
              "value": "1"
            }],
            "title": "省代",
            "type": "button"
          }, {
            "field": [{
              "key": "area_type",
              "value": "2"
            }],
            "title": "董事",
            "type": "button"
          }, {
            "field": [{
              "key": "area_type",
              "value": "3"
            }],
            "title": "合伙达人",
            "total": 0,
            "type": "button"
          }],
          "id": 0,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "会员资格",
          "type": 0,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [],
            "title": "今天",
            "type": "button"
          }, {
            "field": [],
            "title": "昨天",
            "type": "button"
          }, {
            "field": [],
            "title": "最近7天",
            "type": "button"
          }, {
            "field": [],
            "title": "本月",
            "type": "button"
          }, {
            "field": [],
            "title": "本年",
            "type": "button"
          }, {
            "field": [],
            "title": "自定义",
            "type": "custom-datetime-range"
          }],
          "id": 1,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "达标时间",
          "type": 1,
          "remoteFunc": ["modelConditionFunc_1"]
        }, {
          "children": [{
            "field": [{
              "desc": "会员编号",
              "key": "user_number",
              "value": ""
            }],
            "operator": ["gte", "lte", "gt", "lt", "eq", "ne", "like", "notLike", "in", "between"],
            "placeholder": "请输入",
            "type": "input-complex"
          }, {
            "field": [{
              "desc": "期数",
              "key": "expect_num"
            }],
            "operator": [],
            "placeholder": "请输入",
            "type": "input-complex"
          }, {
            "field": [{
              "desc": "顶点搜索",
              "key": "top_number"
            }],
            "operator": [],
            "placeholder": "请输入",
            "type": "input-complex"
          }],
          "id": 2,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "按条件",
          "type": 2,
          "remoteFunc": []
        }],
        "type": "searchTemplate"
      },
      tableModel_1001: {
        "border": true,
        "del": false,
        "headerButton": [{
          "id": "",
          "tag": "add",
          "title": "添加",
          "type": "primary"
        }],
        "id": "id_1001",
        "isGroup": false,
        "isTree": false,
        "rowButton": [{
          "id": "",
          "tag": "del",
          "title": "删除",
          "type": "danger"
        }],
        "showPagination": true,
        "showSelection": false,
        "showSummary": false,
        "stripe": true,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "ID",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "id"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "期数",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "expect_num"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "会员编号",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "user_number"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "用户姓名",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "t_user.real_name"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "手机号",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "t_user.phone"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "达标月份",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "month"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "达标资格",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "select",
          "width": null,
          "prop": "area_type"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "达标日期",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "datetime",
          "width": null,
          "prop": "created_at"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "备注",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "remark"
        }],
        "tableName": "",
        "tableRadioProps": {},
        "tableSelectProps": {
          "area_type": {
            "data": [{
              "key": 1,
              "value": "省代"
            }, {
              "key": 2,
              "value": "董事"
            }, {
              "key": 3,
              "value": "合伙达人"
            }, {
              "key": 0,
              "value": "乡镇"
            }],
            "id": 0,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          }
        },
        "tableSwitchProps": {},
        "title": "区域考核记录",
        "type": "tableTemplate"
      },
      remoteFunc: {
        modelConditionFunc_1: function modelConditionFunc_1(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: "created_at",
            value: []
          }], [{
            key: "created_at",
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: "created_at",
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: "created_at",
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: "created_at",
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: "created_at",
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: "created_at",
            value: []
          }]];
          resolve(tag, data);
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
      }

      params.where = where;
      areaCheckList(params).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 1:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        params.id = row.id;
      }

      areaCheckHandle(params).then(function _callee2(res) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      });
      areaCheckDel({
        id: ids
      }).then(function _callee3(res) {
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};