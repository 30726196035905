//
//
//
//
//
import areaFitBase from '@/views/pages/member/memberAreaFit/areaFitBase.vue';
export default {
  components: {
    areaFitBase: areaFitBase
  },
  name: 'areaFitBase1',
  data: function data() {
    var _this = this;

    return {
      activeName: '0'
    };
  },
  mounted: function mounted() {},
  methods: {}
};